import React, { useState, useRef } from 'react';
import labBackground from './images/young_family.png';
import fertilityxLogo from './images/fertilityx-logo.png';
import UploadForm from './components/UploadForm';

import './App.css';

function App() {
  
  const [activeTab] = useState('main');
  const [showUploadSection, setShowUploadSection] = useState(true);
  
  const mainRef = useRef(null);
  const videoGalleryRef = useRef(null);
  const uploadRef = useRef(null);
  const contactRef = useRef(null);


  // Sample video URLs - replace these with your actual video URLs
  const videoUrls = [
    { url: "https://fertilityx-cdn-stage.azureedge.net/sperm_seach.mp4", title: "Standard Semen Sample" },
    { url: "https://fertilityx-cdn-stage.azureedge.net/advanced_search.mp4", title: "Azoospermia Case: Dense Sample with Multiple Cell Types" },
    { url: "https://fertilityx-cdn-stage.azureedge.net/many_sperms.mp4", title: "Tracking Multiple Sperm Cells in Field" },
    { url: "https://fertilityx-cdn-stage.azureedge.net/testicular_tissues.mp4", title: "Azoospermia Case: Testicular Tissue with Blood Cells" },
  ];

  
  
  const getTabStyle = (tabName) => ({
    marginRight: '10px',
    marginBottom: '5px',
    padding: '5px',
    color: '#7C91B5',
    cursor: 'pointer',
    fontWeight: activeTab === tabName ? 'bold' : 'normal',    
    fontSize: 'clamp(12px, 2vw, 16px)',
    height: 'clamp(30px, 6vw, 40px)',
    width: 'clamp(80px, 15vw, 120px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    borderRadius: '8px',
    backgroundColor: '#E9DFE7',
    outline: 'none',    
  });

  

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  
  // Add this CSS to your App.css file or in a style tag in your HTML
const style = document.createElement('style');
style.textContent = `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;
document.head.appendChild(style);

  return (
    <div className="App" style={{ 
      backgroundColor: '#FFFFFF',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      }}>
<header className="App-header" style={{ 
  backgroundColor: 'transparent',
  minHeight: '80px',
  flexShrink: 0
}}>
  <div className="header-container" >
    <div style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px',
    }}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: '1 1 20%'
      }}>
        <div onClick={() => scrollToSection(mainRef)} style={{
          fontSize: 'clamp(18px, 4vw, 32px)',
          fontWeight: '300',
          fontStyle: 'italic',
          fontFamily: 'cursive',
          color: 'var(--primary-blue-color)',
          display: 'flex',
          flexDirection: 'row',
          padding: '0 30px',
          cursor: 'pointer'
        }}>
          <div><img src={fertilityxLogo} style={{ width: '40px', height: '40px' }} alt="FertilityX Logo" /></div>
          <div>FertilityX</div>
          <div style={{ marginLeft: '10px', color: 'var(--secondary-gray-color)' }}>AI</div>
        </div>        
      </div>
    </div>
    <nav className="header-nav">
      {/*<button onClick={() => scrollToSection(mainRef)} style={getTabStyle('main')}>Home</button> */}
      <button onClick={() => scrollToSection(videoGalleryRef)} style={getTabStyle('video_gallery')}>Demo</button>
      {showUploadSection && (
        <button onClick={() => scrollToSection(uploadRef)} style={getTabStyle('upload')}>Try it now</button>
      )}
      <button onClick={() => scrollToSection(contactRef)} style={getTabStyle('contact')}>Contact Us</button>
    </nav>
  </div>
</header>
        <div className="scrollable-content" style={{ 
        flex: 1,
        overflowY: 'auto',
        scrollBehavior: 'smooth'
      }}>
        <section ref={mainRef} style={{ minHeight: '100vh', padding: '20px' }}>
          {/* Main content */}
          <div style={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            maxWidth: '1200px',
            margin: '0 auto'
          }}>
            <div style={{ 
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}>
              <div style={{ 
                flex: '1 1 300px',
                minWidth: '300px',
                padding: '20px 20px 0 20px',
                textAlign: 'center'
              }}>
                <h1 style={{ 
                  fontSize: 'clamp(1.5em, 5vw, 2.5em)', 
                  color: 'var(--secondary-gray-color)' 
                }}>Welcome to FertilityX.AI</h1>
                          
                <div style={{
                  textAlign: 'left',
                  marginTop: '20px',
                  marginBottom: '20px'
                }}>
                  <p style={{
                    fontSize: 'clamp(1em, 3vw, 1.2em)',
                    color: 'var(--secondary-gray-color)',
                    fontWeight: 'bold',
                    marginBottom: '10px'
                  }}>
                    Our X Factor:
                  </p>
                  <ul style={{
                    listStyleType: 'none',
                    padding: 0,
                    margin: 0
                  }}>
                    <li style={{
                      fontSize: 'clamp(0.9em, 2.5vw, 1.1em)',
                      color: 'var(--secondary-gray-color)',
                      marginBottom: '5px'
                    }}>
                      • Efficient – Get results in less time
                    </li>
                    <li style={{
                      fontSize: 'clamp(0.9em, 2.5vw, 1.1em)',
                      color: 'var(--secondary-gray-color)',
                      marginBottom: '5px'
                    }}>
                      • Optimized – Designed for peak performance
                    </li>
                    <li style={{
                      fontSize: 'clamp(0.9em, 2.5vw, 1.1em)',
                      color: 'var(--secondary-gray-color)',
                      marginBottom: '5px'
                    }}>
                      • Effective – Practical, results-driven approach
                    </li>
                    <li style={{
                      fontSize: 'clamp(0.9em, 2.5vw, 1.1em)',
                      color: 'var(--secondary-gray-color)',
                      marginBottom: '5px'
                    }}>
                      • Revolutionizing - cutting-edge AI technology.
                    </li>
                  </ul>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                <button 
  onClick={() => scrollToSection(videoGalleryRef)}
  className="watch-demo-button"
>
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', alignItems: 'center' }}>
    <div style={{ 
      height: '24px',
      width: '24px',
      marginRight: '10px'
     }}>
      <svg 
        xmlns="http://www.w3.org/2000/svg" 
        width="24" 
        height="24" 
        viewBox="0 0 24 24" 
        fill="white" 
      >
        <path d="M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4z"/>
      </svg>
    </div>
    <div style={{ width: '200px'}}>
      Watch Demo
    </div>
  </div>
</button>

<button 
  onClick={() => scrollToSection(uploadRef)}
  className="watch-demo-button"
>
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <div style={{ 
      height: '24px',
      width: '24px',
      marginRight: '10px'
     }}>
      <svg 
  xmlns="http://www.w3.org/2000/svg" 
  width="24" 
  height="24" 
  viewBox="0 0 24 24" 
  fill="white"
>
  <path d="M9 16h6v-6h4l-7-7-7 7h4v6zm-4 2h14v2H5v-2z"/>

      </svg>
      
    </div>
    <div style={{ width: '200px'}}>
      Upload your own video
    </div>
  </div>
</button>
</div>
                <div style={{
                  fontSize: 'clamp(24px, 4vw, 36px)',                  
                  color: 'var(--secondary-gray-color)',
                  paddingTop: '40px',
                  fontStyle: 'italic'
                }}>
                  We find it!
                </div>
              </div>
              <div style={{ 
                flex: '1 1 300px',
                minWidth: '300px',
                maxWidth: '500px', 
                padding: '20px',
                display: 'flex',
                justifyContent: 'center'
              }}>
                <img 
                  src={labBackground} 
                  alt="Lab background" 
                  style={{ 
                    width: '100%',
                    height: 'auto',
                    maxHeight: '300px',
                    objectFit: 'contain'
                  }} 
                />
              </div>
            </div>
          </div>
        </section>

        <section ref={videoGalleryRef} style={{ minHeight: '100vh', padding: '20px 20px 20px' }}>
          {/* Video Gallery content */}
          <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', paddingLeft: '0px' }}>
            <h2 style={{ width: '100%', textAlign: 'left', fontSize: '24px', fontWeight: 'bold' }}>
              Sperm Tracking & Detection Demos
            </h2>
            <div style={{ width: '100%', textAlign: 'left', fontSize: '18px', fontWeight: 'normal', disply: 'block' }}>
              <div style={{ marginBottom: '10px' ,display: 'flex', flexDirection: 'column' ,
                padding: '0 10px'

              }}>
                <div style={{ marginBottom: '10px' }}>Watch how FertilityX.AI helps identify and track sperm in various situations:</div>
                <div>When a sperm is detected, our system marks it  and keeps track of its movements.</div>
              </div>        
            </div>
            <div className="video-container">
              {videoUrls.map((video, index) => (
                <div key={index} className="video-item">
                  <h3 style={{ 
                    fontSize: '18px', 
                    marginBottom: '10px', 
                    textAlign: 'left',
                  }}>{video.title}</h3>
                  <video width="100%" controls muted>
                    <source src={video.url} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              ))}
            </div>
            <h2 style={{ width: '100%', textAlign: 'left', fontSize: '24px', fontWeight: 'bold' }}>
              Even in the hardest cases!
            </h2>
          </div>
        </section>

        {showUploadSection && (
        <section ref={uploadRef} style={{ minHeight: '100vh', padding: '20px 20px 20px', justifyContent: 'center' }}>
          {/* Upload content */}
          <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'left' }}>
            
          <h2 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '20px' }}>Try it now with your own video</h2>
          <div style={{ 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
           }}>
          <div style={{ 
            textAlign: 'left' ,fontSize: '20px', fontWeight: 'normal', width: '80%', display: 'flex', flexWrap: 'wrap', 
            paddingBottom: '20px'
          }}>
              <span>Upload your own video and get an immediate & thurough report analysing the sample findings.</span>
              <span>You are invited to utilize our demo site at your convenience.</span> 
              <span>Reports will be generated and sent within 2 business days. We are actively working to optimize our system to provide results within 3 hours.</span>
          </div>
          <UploadForm />
                                  
        </div>
         
        
            </div>
        </section>
        )}
        <section ref={contactRef} style={{ minHeight: '50vh', padding: '120px 20px 20px' }}>
          <h2 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '20px' }}>Contact Us</h2>
          <div style={{ display: 'flex', justifyContent: 'center', gap: '50px' }}>
            <a href="https://www.linkedin.com/company/fertilityx" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img src="https://cdn-icons-png.flaticon.com/512/174/174857.png" alt="LinkedIn" style={{ width: '50px', height: '50px' }} />
                <span style={{ marginTop: '10px', color: '#0077B5' }}>LinkedIn</span>
              </div>
            </a>
            <a href="https://forms.gle/DN9j7aSeW942QHVL6" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img src="https://upload.wikimedia.org/wikipedia/commons/5/5b/Google_Forms_2020_Logo.svg" alt="Google Forms" style={{ width: '50px', height: '50px' }} />
                <span style={{ marginTop: '10px', color: '#673AB7' }}>Contact Form</span>
              </div>
            </a>
          </div>
        </section>
      </div>
        
      <footer style={{ 
  backgroundColor: '#FFFFFF',
  padding: '10px',
  color: '#7a7979',
  fontSize: '14px',
  flexShrink: 0
}}>
  <div className="footer-content">
    <p>This product is for experimental use only and is not yet commercially available.</p>
    <p>We appreciate your understanding as we continue to build and refine our platform.</p>
  </div>
</footer>
    </div>
  );
}

export default App;